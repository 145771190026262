import { AmortizationTypes } from '@app/claims/types/amortissement';
import { CancelClaimStatus, RejectionForForfeitureMotif } from "@app/claims/types/claimCancel";
import { ClaimsContrat } from "@app/contracts/helpers/contract-details.helper";
import { AuditTrailYear } from '@app/contracts/types/audit-trail';
import { ClaimStatus, ManagementEventCode, SamplingStatus } from '@app/dashboard/types/claims';
import { InputChoice } from '@bpi/shared-components/core';
import {ClaimType} from "@app/shared/types/claim-types";
import {AnnualRecoveryAmount, DeclarationType} from "@app/shared/types/declaration";
import {DetailReview} from "@app/claims/types/review";
import {Observable} from "rxjs";
import {ApiResponse} from "@app/shared/types/api-response";
import {Status} from "@app/shared/types/status";

import {DetailDataType} from './text-display-type';
import {FieldsAttachmentCode, FieldsCode, FieldsValueCode,} from './field-code';
import {ContractStatus} from './contract-status';
import {Commission, ContractDTO, OtherContractDTO, Schedule,} from './contract';

export interface SharedDetails {
  [FieldsValueCode.BANK_CODE]: string;
  [FieldsValueCode.BANK_NAME]: string;
  [FieldsValueCode.COMPANY_NAME]?: string;
  [FieldsValueCode.WARRANTY_CONTRACT_ID]: string;
  [FieldsValueCode.FINANCING_CONTRACT_ID]: string;
  [FieldsValueCode.LOAN_AMOUNT]: number;
  [FieldsValueCode.OUTSTANDING_CAPITAL]: number;
  [FieldsValueCode.SIREN_NUMBER]: string;
}
export interface ContractDetails extends SharedDetails {
  [FieldsValueCode.BALANCE_SHEET]: number;
  [FieldsValueCode.FEES_RATE]: number;
  [FieldsValueCode.INNOVATIVE_COMPANY_IND]: boolean;
  [FieldsValueCode.SAMPLE_CONTRACT_IND]: string;
  [FieldsValueCode.AMORTIZATION_MODE]: AmortizationTypes;
  [FieldsValueCode.BORROWER_ADDRESS_FULL]: string;
  [FieldsValueCode.BORROWER_ADDRESS1]: string;
  [FieldsValueCode.BORROWER_ADDRESS2]: string;
  [FieldsValueCode.BORROWER_ADDRESS3]: string;
  [FieldsValueCode.BORROWER_CITY]: string;
  [FieldsValueCode.BORROWER_FIRST_NAME]: string;
  [FieldsValueCode.BORROWER_LAST_NAME]: string;
  [FieldsValueCode.BORROWER_POST_CODE]: number;
  [FieldsValueCode.CHECKED_CONTRACT_IND]: boolean;
  [FieldsValueCode.CURRENT_RISK]: number;
  [FieldsValueCode.CONTRACT_STATUS]: ContractStatus;
  [FieldsValueCode.EMPLOYEES_NUMBER]: number;
  [FieldsValueCode.FREQUENCY]: string;
  [FieldsValueCode.LEGAL_STATUS_CODE]: string;
  [FieldsValueCode.LEGAL_STATUS_TITLE]: string;
  [FieldsValueCode.LOAN_DURATION]: number;
  [FieldsValueCode.LOAN_END_DATE]: string;
  [FieldsValueCode.CONTRACT_REFERENCE]: string;
  [FieldsValueCode.LOAN_RELEASING_DATE]: string;
  [FieldsValueCode.NAF_CODE]: string;
  [FieldsValueCode.PARTNER_CUSTOMER_NUMBER]: number;
  [FieldsValueCode.SALARY_MASS]: number;
  [FieldsValueCode.PORTION]: number;
  [FieldsValueCode.REVENUES]: number;
  [FieldsValueCode.PRODUCT]?: string;
  [FieldsValueCode.FEES_RATE_1_24]?: number;
  [FieldsValueCode.FEES_RATE_25_60]?: number;

}

export interface SchedulesCommissionsObject {
  commissions: Commission[];
  schedules: Schedule[];
}

export type ContractAssociatedClaim = Partial<ClaimsContrat>;
export interface ContractObject extends SchedulesCommissionsObject {
  details: ContractDetails;
  otherContracts: OtherContractDTO[];
}

export interface ContractDetailsDTO extends SchedulesCommissionsObject {
  details: ContractDTO;
  otherContracts: OtherContractDTO[];
  claims: ContractAssociatedClaim[];
  auditTrail: AuditTrailYear[];
}

export interface ClaimDetails extends SharedDetails {
  [FieldsValueCode.BALANCE_SHEET]: number;
  [FieldsValueCode.FEES_RATE]: number;
  [FieldsValueCode.INNOVATIVE_COMPANY_IND]: boolean;
  [FieldsValueCode.SAMPLE_CONTRACT_IND]: string;
  [FieldsValueCode.CLAIM_ID]: string;
  [FieldsValueCode.AMOUNT_ACCESS]: number;
  [FieldsValueCode.AMOUNT_INTEREST]: number;
  [FieldsValueCode.AMOUNT_RECOVERY]: number;
  [FieldsValueCode.AMOUNT_PAYMENT]?: number;
  [FieldsValueCode.FINAL_AMOUNT_PAYMENT]?: number;
  [FieldsValueCode.AMOUT_PROVISIONAL_PAYMENT]?: number;
  [FieldsValueCode.BANK_COMPENSATED_AMOUNT]: number;
  [FieldsValueCode.BANK_COMPENSABLE_AMOUNT]: number;
  [FieldsValueCode.CLAIM_STATUS]: ClaimStatus;
  [FieldsValueCode.CREDIT_EVENT_CODE]: CREDIT_EVENTS;
  [FieldsValueCode.CREDIT_EVENT_DATE]: string;
  [FieldsValueCode.SAMPLING_STATUS]: SamplingStatus;
  [FieldsValueCode.PROVISIONAL_PAYMENTS]: number[];
  [FieldsValueCode.TYPE_PAYMENT]: PAYMENT_TYPES;
  [FieldsValueCode.USER_ASSIGNED]?: string;
  [FieldsAttachmentCode.ADDITIONALITY_CRITERION_PROOF]?: Attachment;
  [FieldsAttachmentCode.AMORTIZATION_TABLE]?: Attachment;
  [FieldsAttachmentCode.BACKUP_PLAN]?: Attachment;
  [FieldsAttachmentCode.CERTIFIED_ACCOUNTANT_CERTIFICATE]?: Attachment;
  [FieldsAttachmentCode.COLLECTION_COST_PROOF]?: Attachment;
  [FieldsAttachmentCode.DEBT_COUNTDOWN]?: Attachment;
  [FieldsAttachmentCode.DEBT_DECLARATION]?: Attachment;
  [FieldsAttachmentCode.ENDORSEMENT]?: Attachment;
  [FieldsAttachmentCode.FORMAL_NOTICE_LETTER_BANK_TERM_FORFEITURE]?: Attachment;
  [FieldsAttachmentCode.LEGAL_ACTS]?: Attachment;
  [FieldsAttachmentCode.LEGAL_NOTICE_EXTRACT]?: Attachment;
  [FieldsAttachmentCode.LOAN_AGREEMENT]?: Attachment;
  [FieldsAttachmentCode.LOAN_END_CERTIFICATE]?: Attachment;
  [FieldsAttachmentCode.NONASSIGNMENT_DEBT_CERTIFICATE]?: Attachment;
  [FieldsAttachmentCode.PARTIAL_ASSIGNMENT]?: Attachment;
  [FieldsAttachmentCode.PROTOCOL]?: Attachment;
  [FieldsAttachmentCode.RECOVERY_PLAN]?: Attachment;
  [FieldsAttachmentCode.STATUTORY_AUDITOR_CERTIFICATE]?: Attachment;
  [FieldsAttachmentCode.TAX_RETURN]?: Attachment;
  [FieldsAttachmentCode.TOTAL_ASSIGNMENT]?: Attachment;
  [FieldsAttachmentCode.TREASURY_DECISION]?: Attachment;
  [FieldsAttachmentCode.UNCOLLECTIBILITY_CERTIFICATE]?: Attachment;
  [FieldsAttachmentCode.MEDIATION_AGREMENT]?: Attachment;
  [FieldsValueCode.LAWYER_EMAIL]?: string;
  [FieldsValueCode.LAWYER_FIRST_NAME]?: string;
  [FieldsValueCode.LAWYER_LAST_NAME]?: string;
  [FieldsValueCode.CERTIFICATE_DATE]: string;
  [FieldsValueCode.COMPENSABLE_AMOUNT_DATE]: string;
  [FieldsValueCode.INSUFFICIENT_ASSETS_CLOSING_DATE]: string;
  [FieldsValueCode.REQUEST_CREATION_DATE]: string;
  [FieldsValueCode.COMPENSATION_END_DATE]: string;
  [FieldsValueCode.CREDIT_EVENT_MOTIF]: string;
  [FieldsValueCode.CREDIT_EVENT_SUB_MOTIF]: string;
  [FieldsValueCode.REAL_CLIENT_LOAN]: number;
  [FieldsValueCode.COMPANY_REVENUE]: number;
  [FieldsValueCode.COMPANY_EMPLOYEES_NUMBER]: number;
  [FieldsValueCode.WORKFORCE_REQUESTED]: number;
  [FieldsValueCode.WORKFORCE]: number;
  [FieldsValueCode.SALARY_MASS]?: number;
  [FieldsValueCode.PAYROLL]?: number;
  [FieldsValueCode.LAST_CLAIM_UPDATE_DATE]: string;
  [FieldsValueCode.CANCEL_CLAIM_STATUS]: CancelClaimStatus;

  [FieldsValueCode.ID_BRP]: string;
  [FieldsValueCode.ORGANISATION_REVENUE_REQUESTED]: number;
  [FieldsValueCode.PAYROLL_REQUESTED]: number;
  [FieldsValueCode.OPERATIONAL_RANGE_AMORTIZATION_PROFILE_REQUESTED]: string;
  [FieldsValueCode.OUTSTANDING_PRINCIPAL_AT_REARRANGEMENT_DATE]: number;
  [FieldsValueCode.EFFECTIVE_RESCHEDULING_DATE]: string;
  [FieldsValueCode.PAYMENT_DUE_FREQUENCY_REQUESTED]: string;
  [FieldsValueCode.EFFECTIVE_RESCHEDULING_DATE]: string;
  [FieldsValueCode.SUSPENDED_PAYMENT_DUE_NUMBER]: number;
  [FieldsValueCode.PAYMENT_SCHEDULE_TERMINATION_DATE]: string;
  [FieldsValueCode.INTEREST_RATE_REQUESTED]: number;
  [FieldsValueCode.INNOVATIVE_COMPANY]: boolean;
  [FieldsValueCode.CUSTOMER_AGREEMENT_REFERENCE]: string;
  [FieldsValueCode.PRODUCT]: string;
  [FieldsValueCode.CREDIT_RELEASE_DATE]: string;
  [FieldsValueCode.CREDIT_AMOUNT]: number;
  [FieldsValueCode.LOAN_TERM]: number;
  [FieldsValueCode.PAYMENT_DUE_FREQUENCY]: number;
  [FieldsValueCode.DAMPING_MODE]: string;
  [FieldsValueCode.COMMISSION_RATE]: number;
  [FieldsValueCode.INTERVENING_INSTITUTION]: string;
  [FieldsValueCode.SETTLEMENT_AMOUNT]: number;
  [FieldsValueCode.CREATED_BY]: string;
  [FieldsValueCode.IS_CTX_WITH_FORFEITED]: boolean;
  [FieldsValueCode.INSTALLMENT_PAYMENT_REQUEST]: boolean;
  [FieldsValueCode.AMOUNT_TO_KEEP]: number;
  [FieldsValueCode.AMOUNT_FEES_ACCESS]: number;
  [FieldsValueCode.FORFEITED_OUTSTANDING_PRINCIPAL]: number;
  [FieldsValueCode.GUARANTEE_ACTIVATION_DUE_PAYMENT]: number;
  [FieldsValueCode.REMAIN_AMOUT_PROVISIONAL_PAYMENT]: number;
  [FieldsValueCode.AMOUNT_VP_RESTRUCT]: number;
  [FieldsValueCode.AMOUNT_COMPENSATION_VF_FORFEITED]: number;
  [FieldsValueCode.DEFINITIVE_COMPENSATION_STATEMENT_DATE]: string;
  [FieldsValueCode.REARRANGEMENT_FEES_AMOUNT]: number;

  [FieldsValueCode.REJECTION_FORFEITURE_REASON]?: RejectionForForfeitureMotif;
  [FieldsValueCode.REJECTION_FORFEITURE_COMMENT]?: string;
  [FieldsValueCode.MANAGEMENT_EVENT_CODE]: ManagementEventCode;

  [FieldsValueCode.SCHEDULES]?: Schedule[];
  [FieldsValueCode.CLAIM_TYPE]: ClaimType;
}

export interface DeclarationDetailsEcretement extends SharedDetails {
  [FieldsValueCode.DECLARATION_TYPE]: DeclarationType;
  [FieldsValueCode.DECLARATION_ID]: string;
  [FieldsValueCode.ID_BRP]: string;
  [FieldsValueCode.DECLARATION_STATUS]: Status;
  [FieldsValueCode.AMOUNT_TO_BE_REPAID_TO_THE_STATE]: number;
  [FieldsValueCode.AMOUT_PROVISIONAL_PAYMENT]: number;
  [FieldsValueCode.CONTRACT_REFERENCE]: string;
  [FieldsValueCode.REQUEST_CREATION_DATE]: string;
  [FieldsValueCode.OPERATIONAL_RANGE_AMORTIZATION_PROFILE_REQUESTED]: string;
  [FieldsValueCode.YEAR_OF_CLAPPING]: number;
  [FieldsValueCode.CREDIT_EVENT_DATE]: string;
  [FieldsValueCode.CLAIM_ID]: number;
  [FieldsValueCode.RECOVERY_AMOUNT]: number;
  [FieldsValueCode.TOTAL_AMOUNTS_REPAID_TO_THE_STATE]: number;
  [FieldsValueCode.OUTSTANDING_CAPITAL]: number;
  [FieldsValueCode.AMOUNT_INTEREST]: number;
  [FieldsValueCode.AMOUNT_ACCESS]: number;
  [FieldsValueCode.TOTAL_AMOUNT_OF_RESTRUCTURED_DEBT]: number;

  [FieldsValueCode.DECLARATION_TYPE]: DeclarationType;
  [FieldsValueCode.ANNUAL_RECOVERY_AMOUNTS]?: AnnualRecoveryAmount[];
}

export interface DeclarationDetailsStc extends SharedDetails {
  [FieldsValueCode.DECLARATION_TYPE]: DeclarationType;
  [FieldsValueCode.DECLARATION_ID]: string;
  [FieldsValueCode.ID_BRP]: string;
  [FieldsValueCode.DECLARATION_STATUS]: Status;
  [FieldsValueCode.AMOUNT_TO_BE_REPAID_TO_THE_STATE]: number;
  [FieldsValueCode.CONTRACT_REFERENCE]: string;
  [FieldsValueCode.REQUEST_CREATION_DATE]: string;
  [FieldsValueCode.TOTAL_AMOUNT]: number;
  [FieldsValueCode.PAYMENT_DATE]: Date;
  [FieldsValueCode.FINAL_SETTLEMENT_REASON]: string;
  [FieldsValueCode.FINAL_SETTLEMENT_REASON_DESCRIPTION]: string;
  [FieldsValueCode.FINAL_PAYMENT_AMOUNT]: number;
  [FieldsValueCode.TOTAL_PAYMENT_AMOUNT]: number;
  [FieldsValueCode.RECOVERY_AMOUNT]: number;
  [FieldsValueCode.TOTAL_AMOUNTS_REPAID_TO_THE_STATE]: number;
}


export interface AdditionalDeclarationDetails {
  [FieldsValueCode.OPERATIONAL_RANGE_AMORTIZATION_PROFILE_REQUESTED]: string;
  [FieldsValueCode.INTEREST_RATE_REQUESTED]: number;
  [FieldsValueCode.PAYMENT_DUE_FREQUENCY_REQUESTED]: string;
  [FieldsValueCode.EFFECTIVE_RESCHEDULING_DATE]: string;
  [FieldsValueCode.PAYMENT_SCHEDULE_TERMINATION_DATE]: string;
  [FieldsValueCode.SUSPENDED_PAYMENT_DUE_NUMBER]: number;
  [FieldsValueCode.OPERATIONAL_RANGE_AMORTIZATION_PROFILE_REQUESTED_LABEL]: string;
  [FieldsValueCode.PAYMENT_DUE_FREQUENCY_REQUESTED_LABEL]: string;
  [FieldsValueCode.ECRETEMENT_ANNUAL_RECOVERY_AMOUNTS]: AnnualRecoveryAmount[];
  [FieldsValueCode.ECRETEMENT_TOTAL_OF_AMOUNTS_REPAID_TO_THE_STATE]: number;
  [FieldsValueCode.ECRETEMENT_TOTAL_AMOUNT_OF_RESTRUCTURED_DEBT]: number;
  [FieldsValueCode.ECRETEMENT_AMOUNTS_OF_PROVISIONAL_PAYMENTS]: number;
  [FieldsValueCode.ECRETEMENT_AMOUNTS_TO_BE_REPAID_TO_THE_STATE]: number;
  [FieldsValueCode.ECRETEMENT_RECOVERY_AMOUNTS]: number;
  [FieldsValueCode.CREDIT_EVENT_DATE]: string;
  [FieldsValueCode.MEJ_CREDIT_EVENT_TYPE]: CREDIT_EVENT_TYPE;
  [FieldsValueCode.MEJ_VERSEMENT_TYPE]: PAYMENT_TYPES;
}



export interface DeclarationDetailsObject {
  declarationType: DeclarationType;
  declarationDetails: DeclarationDetailsStc | DeclarationDetailsEcretement;
  contractDetails: ContractDetails;
  partnerDetail: PartnerDetail;
  additionalDeclarationDetail: AdditionalDeclarationDetails;
}

export interface PartnerDetail {
  firstName: string;
  lastName: string;
  email: string;
}

export interface ClaimDetailsObject {
  claimDetails: ClaimDetails;
  contractDetails: ContractDetails;
  otherContracts: OtherContractDTO[];
  partnerDetail: PartnerDetail;
}

export interface Attachment {
  documentName?: string;
  downloadLink?: string;
  attachmentId?: string;
  domain?: string;
  iziAttachmentCode?: string;
}

export interface DetailConfigurableProperties {
  help?: string;
  order?: number;
  canHaveFeedback?: boolean;
  typeOfDetail?: string;
}
interface DetailItemBase extends DetailConfigurableProperties {
  fieldCode: FieldsCode | '';
  missing?: boolean;
  feedback?: {
    isValid?: boolean | null;
    comments?: Comment[];
  };
  hasAlert?: boolean;
}

export interface Comment {
  text: String;
  author: String;
  date: Date
}

export type DetailsValueDataType = Exclude<
  DetailDataType,
  | DetailDataType.ATTACHMENT
  | DetailDataType.DATE
  | DetailDataType.MULTIPLE_CHOICES
>;

export interface DetailMultipleChoice extends DetailItemBase {
  label: string;
  value: string;
  choices: InputChoice[];
  dataType: DetailDataType.MULTIPLE_CHOICES;
}

export interface DetailDate extends DetailItemBase {
  label: string;
  value: string;
  dataType: DetailDataType.DATE;
}

export interface CommissionSlice {
  initialCommission: number;
  maximalIndex: number;
  minimalIndex: number;
  tamorCommission: number;
}

export interface DetailAttachment extends DetailItemBase {
  label: string;
  value: Attachment;
  dataType: DetailDataType.ATTACHMENT;
  isNewAttachment?: boolean;
}
export interface DetailValue extends DetailItemBase {
  label: string;
  value: string | number | boolean;
  dataType?: DetailsValueDataType;
}

export interface DetailSchedule extends DetailItemBase {
  label: string;
  value?: Schedule[];
  dataType: DetailDataType.DATATABLE;
}

export interface DetailAnnualRecoveryAmount extends DetailItemBase {
  label: string,
  value?: AnnualRecoveryAmount[];
  dataType: DetailDataType.DATATABLE;
}

export type DetailItem =
  | DetailAttachment
  | DetailValue
  | DetailMultipleChoice
  | DetailDate
  | DetailSchedule
  | DetailAnnualRecoveryAmount;

export interface History {
  date: Date;
  userAction: string;
  userControl: boolean;
  rejectedFieldLabel: string;
  rejectedFieldCode: string;
  rejectedFieldValue: number;
  comment: string;
  user: string;
  managementEventCode: string;
  motifControl: string;
}

export interface DetailsTabGroup {
  groupTitle?: string;
  groupId?: string;
  viewOnly?: boolean;
  histories?: History[];
  details: DetailItem[];
}

export interface DetailsTab {
  tabTitle: string;
  tabId?: string;
  areHelpShown?: boolean;
  hasAttachmentsActionBar?: boolean;
  availableAttachments?: string[];
  groups: DetailsTabGroup[];
}

// eslint-disable-next-line no-shadow
export enum CREDIT_EVENTS {
  SAVED = 'Sauvegarde judiciaire',
  LIQUIDATION = 'Liquidation judiciaire',
  REDRESS = 'Redressement judiciaire',
  RECOVERY = 'Rétablissement professionnel',
  RESTRUCTURING = 'Restructuration > 6 ans',
  FORFEITURE = 'Déchéance du terme du crédit',
  CRISE = 'Procédure de traitement de sortie de crise',
}

// eslint-disable-next-line no-shadow
export enum PaymentCodeTypes {
  FINAL = 'VF',
  PROVISIONAL = 'VP',
  FINAL_PROVISIONAL = 'PF'
}

// eslint-disable-next-line no-shadow
export enum PAYMENT_TYPES {
  VF = 'Versement final',
  VP = 'Versement provisionnel',
  VP_VF = 'Versement: final - provisionnel'
}

// eslint-disable-next-line no-shadow
export enum LOAN_AMOUNT_TYPE {
  LESS_THEN_500K = '- 500K€',
  MORE_THEN_500K = '+ 500K€',
}

// eslint-disable-next-line no-shadow
export enum CREDIT_EVENT_TYPE {
  L = "Liquidation judiciaire",
  S = "Sauvegarde judiciaire",
  R = "Redressement judiciaire",
  D = "Déchéance du terme du crédit",
  X = "Rétablissement professionnel",
  P = "Procédure de traitement de sortie de crise",
  Z = "Procédure collectives étrangères",
  W = "Restructuration"
}

// eslint-disable-next-line no-shadow
export enum CLAIM_TYPE {
  MEJ = 'Mise en jeu',
  RESTRUC = 'Restructuration'
}

// eslint-disable-next-line no-shadow
export enum PAYMENT_TYPES_LABLE {
  FINAL = 'Final',
  PROVISIONAL = 'Provisionnel',
  FINAL_PROVISIONAL = 'Final - Provisionnel'
}

// eslint-disable-next-line no-shadow
export enum USER_ROLE {
  ROLE_MANAGER = 'Manager',
  ROLE_LAWYER = 'Juriste',
}

// eslint-disable-next-line no-shadow
export enum USER_ROLE_CODE {
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_LAWYER = 'ROLE_LAWYER',
}

export const PaymentCodeTypesTranslation: {
  [key in PaymentCodeTypes]: PAYMENT_TYPES;
} = {
  [PaymentCodeTypes.FINAL]: PAYMENT_TYPES.VF,
  [PaymentCodeTypes.PROVISIONAL]: PAYMENT_TYPES.VP,
  [PaymentCodeTypes.FINAL_PROVISIONAL]: PAYMENT_TYPES.VP_VF,
};

export const PaymentCodeTypesLabelTranslation: {
  [key in PaymentCodeTypes]: PAYMENT_TYPES_LABLE;
} = {
  [PaymentCodeTypes.FINAL]: PAYMENT_TYPES_LABLE.FINAL,
  [PaymentCodeTypes.PROVISIONAL]: PAYMENT_TYPES_LABLE.PROVISIONAL,
  [PaymentCodeTypes.FINAL_PROVISIONAL]: PAYMENT_TYPES_LABLE.FINAL_PROVISIONAL,
};

export type UpdateDetailReviewFn = (id: string, updatedDetailReview: DetailReview) => Observable<ApiResponse>;
export type SetManagerCommentFn = (id: string, updatedDetailReview: DetailReview) => Observable<ApiResponse>;
