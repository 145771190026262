// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const environment: Record<string, any> = {
  release: 'c194272c7785a16be1e13dac066159e3881f84fc',
  name: 'dev',
  //apiBase: 'https://izy.dev.api.cloud.bpifrance.fr/bel-middle',
  apiBase: 'https://izy.internal.dev.eks-nprd.cloud.bpifrance.fr/bel-middle',
  apiKey: 'yetCkQYk7G7vIi5fuS13p88TJNAedpP72c82DUhv',
  production: false,
  sso: {
    issuer: 'https://authmoa.web.bpifrance.fr',
    clientId: 'RSHoOcFgo0CxxS2YAXUT',
    discoveryDocumentEndpoint: 'mga/sps/oauth/oauth20/metadata/OIDCP',
  },
  sentryDSN:
    'https://581761ccbd38435b8a5142d937eaf58a@sentry.infra.bel.cloud.bpifrance.fr/42',
  support: {
    email: 'brp-dev@yopmail.com',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
