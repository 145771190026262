import {PartnerDetail, CREDIT_EVENTS, PAYMENT_TYPES, CREDIT_EVENT_TYPE} from "@app/shared/types/details";
import {ContractDTO} from "@app/shared/types/contract";
import {Status} from "@app/shared/types/status";

export interface DeclarationHeaderDto {
  companyName: string,
  amountsOfProvisionalPayments: number,
  amountsOfRemainingProvisionalPayments: number,
  totalOfAmountsRepaidToTheState: number,
  siren: number,
  loanAmount: number,
  bankName: string,
  amountToBeRepaidToTheState: number,
  declarationStatus: Status,
  iziDeclarationId: number,
  declarationType: DeclarationType,
  bankCode: number,
  contractReference: string,
  declarationSendingDateInBRP: Date,
  yearOfDeclaration: number,
  finalSettlementReason: string,
}

export interface DeclarationDetailDto {
  declarationType: DeclarationType;
  declarationData: DeclarationDataEcretement | DeclarationDataStc;
  contractDetails: ContractDTO;
  partnerDetail: PartnerDetail;
  additionalDeclarationData: AdditionalDeclarationData;
}

// eslint-disable-next-line no-shadow
export enum DeclarationType {
  ECRETEMENT = 'ECRETEMENT',
  STC = 'STC',
}

// eslint-disable-next-line no-shadow
export const DeclrationStatusTranslation: { [type in Status]: string } = {
  [Status.SIGNED]: 'declarations.declaration-status.signed',
  [Status.TO_BE_PAID]: 'declarations.declaration-status.to-be-paid',
  [Status.PAID]: 'declarations.declaration-status.paid',

  [Status.DRAFT]: '',
  [Status.TO_BE_ASSIGNED]: '',
  [Status.TO_BE_CHECKED]: '',
  [Status.TO_BE_SIGNED]: '',
  [Status.TO_BE_COMPLETED]: '',
  [Status.REJECTED]: '',
  [Status.DRAFT]: '',
  [Status.RECEIVED]: '',
  [Status.TO_BE_FIXED]: '',
  [Status.CANCELLED]: '',
  [Status.CANCELLED_TECH]: '',
  [Status.CANCELLED_BY_MANAGER]: ''
};

export interface AnnualRecoveryAmount {
  recoveryDueYear: number;
  recoveryDueAmount: number;
}

export interface DeclarationDataEcretement {
  id: string;
  projectId: string;
  payeeLegalName: string;
  status: Status;
  amountToBeRepaidToTheState: number;
  amountsOfProvisionalPayments: number;
  customerAgreementIdentifier: string;
  nationalBankIdentificationCode: string;
  nationalBankName: string;
  siren: string;
  loanAmount: number;
  sendingDate: string;
  yearOfClapping: number;
  financingCustomerAgreementIdentifier: string;
  guaranteeCustomerAgreementIdentifier: string;
  requetorIdentifier: string;
  effectiveRearrangementDate: string;
  operationalRangeAmortizationProfile: string;
  guaranteeRestructuringRequestIdentifier: number;
  recoveryAmounts: number;
  totalOfAmountsRepaidToTheState: number;
  capitalOutstandingFollowingRestructuring: number;
  totalInterestFollowingRestructuring: number;
  totalAccessoriesFollowingRestructuring: number;
  totalAmountOfRestructuredDebt: number;
  annualRecoveryAmounts: AnnualRecoveryAmount[];
}

export interface DeclarationDataStc {
  id: string;
  projectId: string;
  payeeLegalName: string;
  status: Status;
  totalAmount: number;
  paymentDate: Date;
  amountToBeRepaidToTheState: number;
  customerAgreementIdentifier: string;
  nationalBankIdentificationCode: string;
  nationalBankName: string;
  siren: string;
  sendingDate: string;
  financingCustomerAgreementIdentifier: string;
  guaranteeCustomerAgreementIdentifier: string;
  finalSettlementReason: string;
  finalSettlementReasonDescription: string;
  requetorIdentifier: string;
  finalPaymentAmount: number;
  totalPaymentAmount: number;
  recoveryAmounts: number;
  totalOfAmountsRepaidToTheState: number;
}

export interface CommissionSlice {
  initialCommission: number;
  minimalIndex: number;
  maximalIndex: number;
  tamorCommission: number;
}

export interface Payment {
  id: number;
  bic: string;
  iban: string;
}

export interface AdditionalDeclarationData {
  operationalRangeAmortizationProfileRequested: string;
  operationalRangeAmortizationProfileRequestedLable: string;
  paymentDueFrequencyRequested: string;
  paymentDueFrequencyRequestedLabel: string;
  effectiveReschedulingDate: string;
  paymentScheduleTerminationDate: string;
  interestRateRequested: number;
  suspendedPaymentDueNumber: number
  
  anualRecoveryAmounts: AnnualRecoveryAmount[];
  ecretementTotalOfAmountsRepaidToTheState: number;
  ecretementTotalAmountOfRestructuredDebt: number;
  ecretementAmountsOfProvisionalPayments: number;
  ecretementRecoveryAmounts: number;
  ecretementAmountsToBeRepaidToTheState: number;
  creditEventDate: string;
  mejCreditEventType: CREDIT_EVENT_TYPE;
  mejVersementType: PAYMENT_TYPES;
}
